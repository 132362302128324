import { DownOutlined, IdcardOutlined, LoginOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Space } from 'antd';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import useRouter from '../../../hooks/useRouter';
import { useContext } from 'react';
import { AppContextType } from '../../../types/appContext.type';
import { User } from '../../../__generated__/graphql';
import { AppContext } from '../../../AppContext';

const UserProfile = () => {
  const { getCurrentUser } = useContext(AppContext) as AppContextType;
  const {
    location: { pathname },
  } = useRouter();
  const {
    firstName = '',
    lastName = '',
    profileImage,
  } = getCurrentUser() as unknown as User;

  const items = [
    {
      key: 'profile',
      label: <Link to={ROUTES?.PROFILE}>My Profile</Link>,
      icon: <IdcardOutlined />,
    },
    {
      key: 'logout',
      label: <Link to={ROUTES?.LOGOUT}>Logout</Link>,
      icon: <LoginOutlined />,
    },
  ];

  return (
    <Dropdown
      menu={{ items, selectedKeys: [`${pathname?.split('/')?.[1]}`] }}
      trigger={['click']}
    >
      <Space className="pointer d-flex align-center gap-4" size="small">
        <Avatar
          alt={`${firstName?.[0] ?? ''}${lastName?.[0] ?? ''}`}
          src={
            profileImage
              ? profileImage
              : `${firstName?.[0] ?? ''}${lastName?.[0] ?? ''}`
          }
        >
          {profileImage
            ? profileImage
            : `${firstName?.[0] ?? ''}${lastName?.[0] ?? ''}`}
        </Avatar>
        <span className="m-hide">{`${firstName ?? ''} ${lastName ?? ''}`}</span>
        <DownOutlined />
      </Space>
    </Dropdown>
  );
};

export default UserProfile;
