import React, { createContext, useReducer } from 'react';
import { User } from './__generated__/graphql';
import client from './apollo';
import api from './common/api';
import { PROJECT_ID, REFRESH_TOKEN, TOKEN, USER } from './common/constants';
import {
  AppAction,
  AppActionType,
  AppContextType,
  AppState,
} from './types/appContext.type';
import { ChildrenPropType } from './types/common.type';

const getLoggedInUser = (): User => {
  const loggedInUser = localStorage?.getItem(USER);
  return loggedInUser ? JSON.parse(loggedInUser) : null;
};

const initialState: AppState = {
  currentUser: getLoggedInUser() || {},
  authenticated: !!localStorage.getItem(TOKEN),
  authToken: localStorage.getItem(TOKEN),
  projectId: localStorage.getItem(PROJECT_ID),
};

const reducer = (state: AppState, action: AppAction) => {
  switch (action?.type) {
    case AppActionType.setCurrentUser:
      // eslint-disable-next-line no-case-declarations
      const user = action.data || {};
      localStorage.setItem(
        USER,
        user && Object?.keys(user)?.length ? JSON?.stringify(user) : '',
      );
      return { ...state, currentUser: { ...user } };
    case AppActionType.setCurrentRole:
      return { ...state, currentRole: action.data };
    case AppActionType.logout:
      delete api?.defaults?.headers?.common?.Authorization;
      localStorage.clear();
      client?.clearStore();
      return {
        ...initialState,
        authenticated: false,
        authToken: null,
        user: {},
      };
    case AppActionType.setAuthenticated:
      return { ...state, authenticated: action.data };
    case AppActionType.setAuthToken:
      localStorage.setItem(TOKEN, action.data ?? '');
      return { ...state, authToken: action.data };
    case AppActionType.setRefreshToken:
      localStorage.setItem(REFRESH_TOKEN, action.data ?? '');
      return {
        ...state,
        refreshToken: action.data,
      };
    case AppActionType.setProject:
      return { ...state, projects: action.data };
    case AppActionType.setProjectId:
      localStorage.setItem(PROJECT_ID, action.data ?? '');
      return { ...state, projectId: action.data };
    case AppActionType.setActiveSubscriptionPlans:
      return { ...state, subscriptionPlans: action.data };
    case AppActionType.setSubmissions:
      return { ...state, submissions: action.data };
    case AppActionType.setMembers:
      return { ...state, members: action.data };
    default:
      return { ...state };
  }
};

const AppContext = createContext<AppContextType | null>(null);

const AppContextProvider: React.FC<ChildrenPropType> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getToken = () => localStorage?.getItem(TOKEN) || null;
  const getRefreshToken = () => localStorage?.getItem(REFRESH_TOKEN);
  const getCurrentUser = () =>
    localStorage?.getItem(USER)
      ? JSON?.parse(localStorage.getItem(USER) ?? '')
      : {};

  const isAuthenticated = () => state.authenticated;

  const initializeAuth = (
    authToken: string | null,
    userData: User | null,
    refreshToken?: string | null,
  ) => {
    const token = authToken || getToken();
    const user = userData || getCurrentUser();
    const refresh = refreshToken || getRefreshToken();
    if (token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      dispatch({ type: AppActionType.setAuthToken, data: token });
      dispatch({ type: AppActionType.setRefreshToken, data: refresh });
      dispatch({ type: AppActionType.setAuthenticated, data: true });
      dispatch({ type: AppActionType.setCurrentUser, data: user });
    }
  };

  const value: AppContextType = {
    state,
    dispatch,
    isAuthenticated,
    getToken,
    getRefreshToken,
    initializeAuth,
    getCurrentUser,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextConsumer, AppContextProvider };
