import { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { User } from '../../__generated__/graphql';
import useRouter from '../../hooks/useRouter';
import { AppContextType } from '../../types/appContext.type';
import { useLazyQuery } from '@apollo/client';
import { REFRESH_TOKEN } from './graphql/mutations';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';

const RefreshToken = () => {
  const { initializeAuth, getRefreshToken } = useContext(
    AppContext,
  ) as AppContextType;
  const {
    navigate,
    location: { state },
  } = useRouter();
  const refreshToken = getRefreshToken();
  function successCallback(accessToken: string | null, userData: User | null) {
    initializeAuth(accessToken, userData);
  }

  const [refresh, { loading }] = useLazyQuery(REFRESH_TOKEN, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      const accessToken = response?.refreshToken?.accessToken ?? null;
      const userData = response?.refreshToken?.user ?? null;
      successCallback(accessToken, userData);
      if (state?.from) {
        navigate(state?.from, { replace: true });
      } else {
        navigate(ROUTES?.MAIN);
      }
    },
    onError() {
      navigate(ROUTES?.LOGOUT, { replace: true });
    },
  });

  useEffect(() => {
    if (refreshToken)
      refresh({
        variables: {
          data: {
            refreshToken,
          },
        },
      });
  }, []);

  if (loading) return <LoaderComponent />;

  return null;
};

export default RefreshToken;
