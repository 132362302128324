import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Statistic,
  Typography,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import {
  Project,
  ProjectCreationInput,
  ProjectFormsResponse,
  ProjectWithMembersResponse,
} from '../../__generated__/graphql';
import {
  GUTTER_VARIATIONS,
  MESSAGE,
  REGEX,
  ROUTES,
} from '../../common/constants';
import { formItemProps, formValidatorRules } from '../../common/utils';
import { messageContext } from '../../components/AppContextHolder';
import CommonModal from '../../components/modals/CommonModal';
import useRouter from '../../hooks/useRouter';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import './dashboard.less';
import { CREATE_PROJECT } from './graphql/Mutation';
import {
  PROJECT_FORMS,
  PROJECT_MEMBER,
  SUBMISSIONS_DATA,
} from './graphql/Queries';

const { required } = formValidatorRules;
const { Title } = Typography;

const prefixSelector = (
  <Form.Item name="prefix" noStyle>
    <Select
      defaultValue="https://"
      options={[
        {
          value: 'https://',
          label: 'https://',
        },
      ]}
    ></Select>
  </Form.Item>
);

const Dashboard = () => {
  const [form] = Form.useForm();
  const { navigate } = useRouter();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const [formData, setFormData] = useState<ProjectFormsResponse>({});
  const [memberData, setMemberData] = useState<ProjectWithMembersResponse>({});
  const { state, dispatch } = useContext(AppContext) as AppContextType;
  const { projectId, projects } = state;

  const [createProject, { loading }] = useMutation(CREATE_PROJECT, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted: (res) => {
      if (res?.createProject?.data) {
        const newProject = res?.createProject?.data;
        const newProjectId = newProject?.id;
        dispatch({
          type: AppActionType?.setProjectId,
          data: newProjectId as string,
        });
        dispatch({
          type: AppActionType?.setProject,
          data: [...(projects || []), newProject] as Project[],
        });
        setIsModalOpen(false);
      }
    },
  });

  const [projectForm, { loading: loadForms }] = useLazyQuery(PROJECT_FORMS, {
    fetchPolicy: 'network-only',
    onError: () => {},
    onCompleted: (res) => {
      if (res?.projectForms) {
        const projectFormData = res?.projectForms;
        setFormData(projectFormData);
      }
    },
  });

  const [projectMember, { loading: loadMember }] = useLazyQuery(
    PROJECT_MEMBER,
    {
      fetchPolicy: 'network-only',
      onError: () => {},
      onCompleted: (res) => {
        if (res?.projectMembers) {
          const projectMemberData = res?.projectMembers;
          setMemberData(projectMemberData);
        }
      },
    },
  );

  const [submissionData] = useLazyQuery(SUBMISSIONS_DATA, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted: (res) => {
      if (res) {
        dispatch({
          type: AppActionType?.setSubmissions,
          data: res?.getSubmissionDataResolver,
        });
      }
    },
  });

  useEffect(() => {
    if (projectId) {
      setIsModalOpen(false);
      projectForm({
        variables: {
          where: {
            projectId: projectId,
          },
        },
      });
      projectMember({
        variables: {
          where: {
            projectId: projectId,
          },
        },
      });
      submissionData({
        variables: {
          projectId: projectId,
          defaultProjectId: null,
        },
      });
    } else {
      setIsModalOpen(true);
    }
  }, [projectId]);

  const onFinish = async (values: ProjectCreationInput) => {
    const filedValue = form.getFieldValue('prefix');
    try {
      if (values) {
        const websiteAddress = `${filedValue}${values?.websiteAddress}`;
        const urlPattern = REGEX?.WEB_URL;
        if (!urlPattern.test(websiteAddress)) {
          messageContext.error('Website URL is not valid');
        } else {
          const projectData = {
            projectName: values?.projectName,
            websiteAddress: websiteAddress,
          };
          await createProject({
            variables: {
              data: projectData,
            },
          });
          setIsModalOpen(false);
        }
      }
    } catch (error) {
      messageContext.error('Failed...!');
    }
  };

  // useEffect(() => {
  //   form.setFieldValue('prefix', 'https://');
  // }, [form]);

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0 mr-8" level={3}>
        Dashboard
      </Title>
      <div className="site-statistic-demo-card">
        <Spin spinning={loading}>
          <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Card
                onClick={() => {
                  navigate(ROUTES?.FORMS);
                }}
              >
                <Statistic
                  loading={loadForms}
                  title="Forms"
                  value={formData?.formCountString || '0/0'}
                  precision={2}
                  valueStyle={{ color: '#3f8600' }}
                />
              </Card>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Card>
                <Statistic
                  loading={loadMember}
                  title="Members"
                  value={memberData?.memberCountString || '0/0'}
                  precision={2}
                  valueStyle={{ color: '#faad14' }}
                />
              </Card>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Card>
                <Statistic
                  loading={loadForms}
                  title="Submissions"
                  value={formData?.submissionCountString || '0/0'}
                  precision={2}
                  valueStyle={{ color: '#cf1322' }}
                />
              </Card>
            </Col>
          </Row>
        </Spin>
      </div>
      {isModalOpen && (
        <CommonModal open={isModalOpen} showFooter={false} closable={false}>
          <h2 className="project-modal-heading">Let's get started</h2>
          <div className="create-project-form">
            <Form
              form={form}
              onFinish={onFinish}
              name="create-project"
              layout="vertical"
              initialValues={{ prefix: 'https://' }}
            >
              <Form.Item
                label="Project Name"
                name="projectName"
                rules={[{ ...required, message: MESSAGE?.required }]}
                normalize={formItemProps.normalize}
                extra="All your forms will be stored inside this project"
              >
                <Input placeholder="Enter your business name" />
              </Form.Item>
              <Form.Item
                label="Website URL"
                name="websiteAddress"
                rules={[
                  { ...required, message: MESSAGE?.required },
                  // {
                  //   pattern: REGEX?.WEB_URL,
                  //   message: 'Invalid Website URL',
                  // },
                  {
                    validator(_, value) {
                      if (!value) {
                        return Promise?.resolve();
                      }
                      return Promise?.resolve();
                    },
                  },
                ]}
                normalize={formItemProps.normalize}
                extra="Only submissions to this domain and the sub-domains will be accepted."
              >
                <Input
                  addonBefore={prefixSelector}
                  placeholder="yourWebsiteLink.com"
                />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" type="primary" className="full-width">
                  Save and Create
                </Button>
              </Form.Item>
            </Form>
          </div>
        </CommonModal>
      )}
    </>
  );
};

export default Dashboard;
