export const themeConfig = {
  cssVar: true,
  token: {
    colorPrimary: '#414BB2',
    fontFamily: 'unset',
    // radiusBase: '4px',
    // controlRadius: '4px',
    // colorLink: 'var(--color-primary)',
    // colorLinkActive: 'var(--color-primary)',
    // colorLinkHover: 'var(--color-primary)',
  },
  components: {
    Progress: {
      defaultColor: 'rgb(65, 75, 178)',
    },
    Tag: {
      defaultBg: '#414BB2',
      defaultColor: '#FFFFFF',
      borderRadiusSM: 50,
    },
    Badge: {
      colorError: '#414BB2',
    },

    //   Typography: {},
    //   Button: {
    //     borderRadius: 4,
    //     colorPrimaryHover: 'var(--color-primary-light)',
    //     colorPrimary: 'var(--color-primary)',
    //     primaryShadow: 'unset',
    //   },
    //   Menu: { itemSelectedBg: 'var(--color-grey-light)' },
    //   Dropdown: {
    //     controlItemBgActiveHover: 'var(--color-grey-light)',
    //     controlItemBgHover: 'var(--color-grey-light)',
    //     controlItemBgActive: 'var(--color-grey-light)',
    //   },
    //   Pagination: {
    //     colorPrimary: 'var(--color-primary)',
    //     colorPrimaryHover: 'var(--color-primary-light)',
    //   },
    //   Select: {
    //     colorPrimary: 'var(--color-primary)',
    //     colorPrimaryHover: 'var(--color-primary-light)',
    //   },
  },
};
