import { FormOutlined, HomeOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { Footer } from 'antd/es/layout/layout';
import React, { Dispatch, SetStateAction } from 'react';
import { MODULES, ROUTES } from '../../../common/constants';
import FooterContent from '../../../components/FooterContent';
import useRouter from '../../../hooks/useRouter';
import './Sidebar.less';

type MenuItem = Required<MenuProps>['items'][number];
type SideBarType = {
  setActive: Dispatch<SetStateAction<boolean>>;
};

function getItem(
  label: string | React.ReactElement,
  key: string,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: string,
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const menuItems = [
  getItem(MODULES?.DASHBOARD, ROUTES?.MAIN, <HomeOutlined />),
  getItem(MODULES?.FORMS, ROUTES?.FORMS, <FormOutlined />),
];

function Sidebar({ setActive }: SideBarType) {
  const {
    navigate,
    location: { pathname },
  } = useRouter();

  const onMenuSelect = (e: { key: React.Key }) => {
    setActive(false);
    navigate(e.key.toString());
  };

  return (
    <div className="side-bar">
      <div className="sidebar-content">
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={[`/${pathname?.split('/')?.[1]}`]}
          defaultSelectedKeys={[ROUTES?.MAIN]}
          onSelect={onMenuSelect}
          onClick={onMenuSelect}
          items={menuItems}
        />
        <Footer>
          <FooterContent />
        </Footer>
      </div>
    </div>
  );
}

export default Sidebar;
