import { LockOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Spin, Typography } from 'antd';
import { LwForms } from '../../assets/svg';
import { REGEX, ROUTES } from '../../common/constants';
import { formItemProps, formValidatorRules } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import { RESET_PASSWORD, VERIFICATION } from './graphql/mutations';
import { useMutation } from '@apollo/client';
import { UpdatePasswordInput } from '../../__generated__/graphql';
import { messageContext } from '../../components/AppContextHolder';
import { useEffect } from 'react';
import FooterContent from '../../components/FooterContent';
const { Title, Text } = Typography;

const { required } = formValidatorRules;

const ResetPassword = () => {
  const {
    navigate,
    location: { pathname, search },
  } = useRouter();
  const [resetPassword, { loading: resetLoading }] = useMutation(
    RESET_PASSWORD,
    { fetchPolicy: 'network-only', onError() {} },
  );
  const [verifyToken] = useMutation(VERIFICATION, { onError() {} });
  const params = new URLSearchParams(search);
  const token = params?.get('token');
  const uid = params?.get('uid');
  useEffect(() => {
    if (pathname === ROUTES.RESET && token && uid) {
      verifyToken({
        variables: {
          data: {
            token,
            uid,
          },
        },
      });
    }
  }, [token, pathname, uid]);

  const onFinish = (values: UpdatePasswordInput) => {
    try {
      if (token && uid) {
        resetPassword({
          variables: {
            data: {
              password: values.password,
              confirmPassword: values.confirmPassword,
              token: token,
              uid: uid,
            },
          },
          onCompleted: (response) => {
            if (response) {
              navigate(ROUTES.LOGIN, { replace: true });
            }
          },
        });
      }
    } catch (error) {
      messageContext.error('Reset password error:');
    }
  };

  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-center">
        <Card className="full-width">
          <div className="text-center m-16">
            <LwForms />
            <Title level={3} className="text-center m-8">
              Reset Password
            </Title>
            <Text className="text-center">
              Enter a new password for your account
            </Text>
          </div>
          <Spin spinning={resetLoading} wrapperClassName="full-width">
            <Form
              name="reset-password"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              size="large"
            >
              <Form.Item
                name="password"
                rules={[
                  { ...required, message: 'Please enter password!' },
                  {
                    pattern: REGEX.PASSWORD,
                    message:
                      'Password must be at least 8 characters long that includes 1 number, and 1 special character',
                  },
                ]}
                normalize={formItemProps.normalize}
                hasFeedback
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Enter password"
                />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                rules={[
                  { ...required, message: 'Please enter confirm password!' },
                  // {
                  //   pattern: REGEX.PASSWORD,
                  //   message:
                  //     'Password must be at least 8 characters long that includes 1 number, and 1 special character',
                  // },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || value === getFieldValue('password')) {
                        return Promise?.resolve();
                      }
                      return Promise?.reject(
                        new Error('Passwords do not match'),
                      );
                    },
                  }),
                ]}
                normalize={formItemProps.normalize}
                hasFeedback
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Enter confirm password"
                />
              </Form.Item>
              <Form.Item className="full-width mb-8">
                <Button type="primary" className="full-width" htmlType="submit">
                  Reset Password
                </Button>
              </Form.Item>
              <Form.Item className="text-center mb-0">
                <Button
                  type="link"
                  onClick={() => {
                    navigate(ROUTES.LOGIN);
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
        <FooterContent />
      </div>
    </div>
  );
};

export default ResetPassword;
