/* eslint-disable no-unused-vars */
import { Dispatch } from 'react';
import {
  Project,
  ProjectWithMembersResponse,
  SubmissionResult,
  User,
} from '../__generated__/graphql';
import { ActiveSubscriptionDetails } from '../__generated__/graphql';

export type AppState = {
  currentUser: User;
  authenticated: boolean;
  authToken: string | null;
  currentRole?: string;
  refreshToken?: string | null;
  projects?: Project[];
  projectId?: string | null;
  subscriptionPlans?: ActiveSubscriptionDetails;
  submissions?: SubmissionResult;
  members?: ProjectWithMembersResponse;
};

export enum AppActionType {
  setCurrentUser = 'SET_CURRENT_USER',
  setAuthenticated = 'SET_AUTHENTICATED',
  setAuthToken = 'SET_TOKEN',
  setCurrentRole = 'SET_CURRENT_ROLE',
  setRefreshToken = 'SET_REFRESH_TOKEN',
  logout = 'LOGOUT',
  setProject = 'SET_PROJECT',
  setProjectId = 'SET_PROJECT_ID',
  setActiveSubscriptionPlans = 'SET_ACTIVE_SUBSCRIPTION_PLANS',
  setSubmissions = 'SET_SUBMISSIONS',
  setMembers = 'SET_MEMBERS',
}

export type AppAction =
  | { type: AppActionType.setCurrentUser; data: User }
  | { type: AppActionType.setAuthenticated; data: boolean }
  | { type: AppActionType.setAuthToken; data: string | null }
  | { type: AppActionType.setRefreshToken; data: string | null }
  | { type: AppActionType.setCurrentRole; data: string }
  | { type: AppActionType.logout }
  | { type: AppActionType.setProject; data: Project[] | undefined }
  | { type: AppActionType.setProjectId; data: string | null }
  | {
      type: AppActionType.setActiveSubscriptionPlans;
      data: ActiveSubscriptionDetails;
    }
  | {
      type: AppActionType.setSubmissions;
      data: SubmissionResult | undefined;
    }
  | {
      type: AppActionType.setMembers;
      data: ProjectWithMembersResponse;
    };

export type AppContextType = {
  state: AppState;
  dispatch: Dispatch<AppAction>;
  getToken: () => string | null;
  isAuthenticated: () => boolean;
  getRefreshToken: () => string | null;
  initializeAuth: (
    authToken: string | null,
    userData: User | null,
    refreshToken?: string | null,
  ) => void;
  getCurrentUser: () => void;
};
