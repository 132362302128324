import { gql } from '../../../__generated__';

export const SIGN_UP = gql(`
mutation SignUp($data: SignUpInputData!) {
  signUp(data: $data) {
    message
  }
}
`);

export const LOGIN = gql(`
mutation LoginUser($data: LoginUserInput) {
  loginUser(data: $data) {
    data {
      email
      id
      firstName
      lastName
      profileImage
      role
    }
    accessToken
    message
    refreshToken
  }
}
`);

export const FIREBASE_LOGIN = gql(`
mutation FirebaseLogin($data: FirebaseLoginDataInput) {
  firebaseLogin(data: $data) {
    accessToken
    message
    refreshToken
    data {
      email
      firstName
      lastName
      profileImage
      role
      id
    }
  }
}`);

export const FORGOT_PASSWORD = gql(`
mutation Mutation($data: ForgotPasswordInput) {
  forgotPassword(data: $data) {
    message
  }
}
`);

export const RESET_PASSWORD = gql(`
mutation UpdatePassword($data: UpdatePasswordInput) {
  updatePassword(data: $data) {
    message
  }
}
`);

export const REFRESH_TOKEN = gql(`
mutation RefreshToken($data: RefreshTokenInput) {
  refreshToken(data: $data) {
    accessToken
    user {
      email
      id
      firstName
      lastName
      profileImage
      role
    }
  }
}
`);

export const UPDATE_CURRENT_USER = gql(`
mutation UpdateUser($data: UpdateUserInput) {
  updateUser(data: $data) {
    data {
      email
      firstName
      lastName
      id
      profileImage
      role
    }
    message
  }
}`);

export const LOGOUT_USER = gql(`
mutation LogoutUser {
  logoutUser {
    message
  }
}
`);

export const VERIFICATION = gql(`
mutation EmailVerification($data: EmailVerificationInput) {
  emailVerification(data: $data) {
    message
  }
}`);

export const CHANGE_PASSWORD = gql(`
mutation ChangePassword($data: ChangePasswordInput) {
  changePassword(data: $data) {
    message
  }
}`);
