import { gql } from '../../../__generated__';

export const CREATE_PROJECT = gql(`
mutation CreateProject($data: ProjectCreationInput!) {
  createProject(data: $data) {
    message
    data {
      id
      projectName
      websiteAddress
    }
  }
}
`);
