import { signInWithPopup } from 'firebase/auth';
import { auth, googleProvider, microsoftProvider } from '../index';

export const signInWithGooglePopup = async () => {
  try {
    const { user } = await signInWithPopup(auth, googleProvider);
    return { user };
  } catch (error) {
    return { token: null };
  }
};

export const signInWithMicrosoftPopup = async () => {
  try {
    const { user } = await signInWithPopup(auth, microsoftProvider);
    return { user };
  } catch (error) {
    return { token: null };
  }
};
