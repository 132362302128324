/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\nmutation SignUp($data: SignUpInputData!) {\n  signUp(data: $data) {\n    message\n  }\n}\n": types.SignUpDocument,
    "\nmutation LoginUser($data: LoginUserInput) {\n  loginUser(data: $data) {\n    data {\n      email\n      id\n      firstName\n      lastName\n      profileImage\n      role\n    }\n    accessToken\n    message\n    refreshToken\n  }\n}\n": types.LoginUserDocument,
    "\nmutation FirebaseLogin($data: FirebaseLoginDataInput) {\n  firebaseLogin(data: $data) {\n    accessToken\n    message\n    refreshToken\n    data {\n      email\n      firstName\n      lastName\n      profileImage\n      role\n      id\n    }\n  }\n}": types.FirebaseLoginDocument,
    "\nmutation Mutation($data: ForgotPasswordInput) {\n  forgotPassword(data: $data) {\n    message\n  }\n}\n": types.MutationDocument,
    "\nmutation UpdatePassword($data: UpdatePasswordInput) {\n  updatePassword(data: $data) {\n    message\n  }\n}\n": types.UpdatePasswordDocument,
    "\nmutation RefreshToken($data: RefreshTokenInput) {\n  refreshToken(data: $data) {\n    accessToken\n    user {\n      email\n      id\n      firstName\n      lastName\n      profileImage\n      role\n    }\n  }\n}\n": types.RefreshTokenDocument,
    "\nmutation UpdateUser($data: UpdateUserInput) {\n  updateUser(data: $data) {\n    data {\n      email\n      firstName\n      lastName\n      id\n      profileImage\n      role\n    }\n    message\n  }\n}": types.UpdateUserDocument,
    "\nmutation LogoutUser {\n  logoutUser {\n    message\n  }\n}\n": types.LogoutUserDocument,
    "\nmutation EmailVerification($data: EmailVerificationInput) {\n  emailVerification(data: $data) {\n    message\n  }\n}": types.EmailVerificationDocument,
    "\nmutation ChangePassword($data: ChangePasswordInput) {\n  changePassword(data: $data) {\n    message\n  }\n}": types.ChangePasswordDocument,
    "query GetCurrentUser {\n  getCurrentUser {\n    email\n    id\n    firstName\n    lastName\n    profileImage\n    role\n  }\n}": types.GetCurrentUserDocument,
    "\nquery GetProfileImageUploadSignedUrl($data: GetProfileImageUploadSignedUrlInput) {\n  getProfileImageUploadSignedUrl(data: $data) {\n    key\n    signedUrl\n  }\n}": types.GetProfileImageUploadSignedUrlDocument,
    "\n query GetActiveSubscription($projectId: String!) {\n  getActiveSubscription(projectId: $projectId) {\n    planName\n    status\n    features\n    autoRenewalDate\n    renewalAmount\n    paymentMethods\n    billingHistory {\n      amount\n      description\n      createdAt\n    }\n  }\n}": types.GetActiveSubscriptionDocument,
    "\nmutation CreateProject($data: ProjectCreationInput!) {\n  createProject(data: $data) {\n    message\n    data {\n      id\n      projectName\n      websiteAddress\n    }\n  }\n}\n": types.CreateProjectDocument,
    "\nquery ProjectForms($where: ProjectFormsDataInput!, $filters: ProjectFormsFilters) {\n    projectForms(where: $where, filters: $filters) {\n      projectName\n      projectForms {\n        createdAt\n        name\n        submissionCount\n        id\n      }\n      emptyStateMessage\n      errorMessage\n      formCountString\n      submissionCountString\n    }\n  }\n": types.ProjectFormsDocument,
    "\nquery ProjectMembers($where: ProjectMemberDataInput!, $filters: ProjectMembersFilters) {\n  projectMembers(where: $where, filters: $filters) {\n    projectName\n    memberCountString\n    errorMessage\n    projectMembers {\n      id\n      role\n      invitationStatus\n      status\n      user {\n        email\n        id\n        firstName\n        lastName\n        profileImage\n        role\n      }\n    }\n  }\n}": types.ProjectMembersDocument,
    "\n  query GetProjects {\n    getProjects {\n      projects {\n        id\n        projectName\n        websiteAddress\n      }\n    }\n  }": types.GetProjectsDocument,
    "\n  query GetSubmissionDataResolver($projectId: String, $dateRange: String, $defaultProjectId: String) {\n  getSubmissionDataResolver(projectId: $projectId, dateRange: $dateRange, defaultProjectId: $defaultProjectId) {\n    submissions {\n      id\n      formId\n      createdAt\n      submissionData\n    }\n    canAccessFiles\n    totalStorageUsed\n    totalSubmissions\n  }\n}": types.GetSubmissionDataResolverDocument,
    "\nmutation ProjectFormCreation($data: CreateFormInput!) {\n    projectFormCreation(data: $data) {\n      data {\n        id\n        name\n        projectId\n      }\n      message\n    }\n  }\n": types.ProjectFormCreationDocument,
    "\nmutation DeleteForm($data: DeleteFormInput!) {\n  deleteForm(data: $data) {\n    message\n  }\n}": types.DeleteFormDocument,
    "\n  mutation EditForm($data: EditFormInput!) {\n  editForm(data: $data) {\n    message\n  }\n}": types.EditFormDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation SignUp($data: SignUpInputData!) {\n  signUp(data: $data) {\n    message\n  }\n}\n"): (typeof documents)["\nmutation SignUp($data: SignUpInputData!) {\n  signUp(data: $data) {\n    message\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation LoginUser($data: LoginUserInput) {\n  loginUser(data: $data) {\n    data {\n      email\n      id\n      firstName\n      lastName\n      profileImage\n      role\n    }\n    accessToken\n    message\n    refreshToken\n  }\n}\n"): (typeof documents)["\nmutation LoginUser($data: LoginUserInput) {\n  loginUser(data: $data) {\n    data {\n      email\n      id\n      firstName\n      lastName\n      profileImage\n      role\n    }\n    accessToken\n    message\n    refreshToken\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation FirebaseLogin($data: FirebaseLoginDataInput) {\n  firebaseLogin(data: $data) {\n    accessToken\n    message\n    refreshToken\n    data {\n      email\n      firstName\n      lastName\n      profileImage\n      role\n      id\n    }\n  }\n}"): (typeof documents)["\nmutation FirebaseLogin($data: FirebaseLoginDataInput) {\n  firebaseLogin(data: $data) {\n    accessToken\n    message\n    refreshToken\n    data {\n      email\n      firstName\n      lastName\n      profileImage\n      role\n      id\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation Mutation($data: ForgotPasswordInput) {\n  forgotPassword(data: $data) {\n    message\n  }\n}\n"): (typeof documents)["\nmutation Mutation($data: ForgotPasswordInput) {\n  forgotPassword(data: $data) {\n    message\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation UpdatePassword($data: UpdatePasswordInput) {\n  updatePassword(data: $data) {\n    message\n  }\n}\n"): (typeof documents)["\nmutation UpdatePassword($data: UpdatePasswordInput) {\n  updatePassword(data: $data) {\n    message\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation RefreshToken($data: RefreshTokenInput) {\n  refreshToken(data: $data) {\n    accessToken\n    user {\n      email\n      id\n      firstName\n      lastName\n      profileImage\n      role\n    }\n  }\n}\n"): (typeof documents)["\nmutation RefreshToken($data: RefreshTokenInput) {\n  refreshToken(data: $data) {\n    accessToken\n    user {\n      email\n      id\n      firstName\n      lastName\n      profileImage\n      role\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation UpdateUser($data: UpdateUserInput) {\n  updateUser(data: $data) {\n    data {\n      email\n      firstName\n      lastName\n      id\n      profileImage\n      role\n    }\n    message\n  }\n}"): (typeof documents)["\nmutation UpdateUser($data: UpdateUserInput) {\n  updateUser(data: $data) {\n    data {\n      email\n      firstName\n      lastName\n      id\n      profileImage\n      role\n    }\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation LogoutUser {\n  logoutUser {\n    message\n  }\n}\n"): (typeof documents)["\nmutation LogoutUser {\n  logoutUser {\n    message\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation EmailVerification($data: EmailVerificationInput) {\n  emailVerification(data: $data) {\n    message\n  }\n}"): (typeof documents)["\nmutation EmailVerification($data: EmailVerificationInput) {\n  emailVerification(data: $data) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation ChangePassword($data: ChangePasswordInput) {\n  changePassword(data: $data) {\n    message\n  }\n}"): (typeof documents)["\nmutation ChangePassword($data: ChangePasswordInput) {\n  changePassword(data: $data) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query GetCurrentUser {\n  getCurrentUser {\n    email\n    id\n    firstName\n    lastName\n    profileImage\n    role\n  }\n}"): (typeof documents)["query GetCurrentUser {\n  getCurrentUser {\n    email\n    id\n    firstName\n    lastName\n    profileImage\n    role\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetProfileImageUploadSignedUrl($data: GetProfileImageUploadSignedUrlInput) {\n  getProfileImageUploadSignedUrl(data: $data) {\n    key\n    signedUrl\n  }\n}"): (typeof documents)["\nquery GetProfileImageUploadSignedUrl($data: GetProfileImageUploadSignedUrlInput) {\n  getProfileImageUploadSignedUrl(data: $data) {\n    key\n    signedUrl\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n query GetActiveSubscription($projectId: String!) {\n  getActiveSubscription(projectId: $projectId) {\n    planName\n    status\n    features\n    autoRenewalDate\n    renewalAmount\n    paymentMethods\n    billingHistory {\n      amount\n      description\n      createdAt\n    }\n  }\n}"): (typeof documents)["\n query GetActiveSubscription($projectId: String!) {\n  getActiveSubscription(projectId: $projectId) {\n    planName\n    status\n    features\n    autoRenewalDate\n    renewalAmount\n    paymentMethods\n    billingHistory {\n      amount\n      description\n      createdAt\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation CreateProject($data: ProjectCreationInput!) {\n  createProject(data: $data) {\n    message\n    data {\n      id\n      projectName\n      websiteAddress\n    }\n  }\n}\n"): (typeof documents)["\nmutation CreateProject($data: ProjectCreationInput!) {\n  createProject(data: $data) {\n    message\n    data {\n      id\n      projectName\n      websiteAddress\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery ProjectForms($where: ProjectFormsDataInput!, $filters: ProjectFormsFilters) {\n    projectForms(where: $where, filters: $filters) {\n      projectName\n      projectForms {\n        createdAt\n        name\n        submissionCount\n        id\n      }\n      emptyStateMessage\n      errorMessage\n      formCountString\n      submissionCountString\n    }\n  }\n"): (typeof documents)["\nquery ProjectForms($where: ProjectFormsDataInput!, $filters: ProjectFormsFilters) {\n    projectForms(where: $where, filters: $filters) {\n      projectName\n      projectForms {\n        createdAt\n        name\n        submissionCount\n        id\n      }\n      emptyStateMessage\n      errorMessage\n      formCountString\n      submissionCountString\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery ProjectMembers($where: ProjectMemberDataInput!, $filters: ProjectMembersFilters) {\n  projectMembers(where: $where, filters: $filters) {\n    projectName\n    memberCountString\n    errorMessage\n    projectMembers {\n      id\n      role\n      invitationStatus\n      status\n      user {\n        email\n        id\n        firstName\n        lastName\n        profileImage\n        role\n      }\n    }\n  }\n}"): (typeof documents)["\nquery ProjectMembers($where: ProjectMemberDataInput!, $filters: ProjectMembersFilters) {\n  projectMembers(where: $where, filters: $filters) {\n    projectName\n    memberCountString\n    errorMessage\n    projectMembers {\n      id\n      role\n      invitationStatus\n      status\n      user {\n        email\n        id\n        firstName\n        lastName\n        profileImage\n        role\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetProjects {\n    getProjects {\n      projects {\n        id\n        projectName\n        websiteAddress\n      }\n    }\n  }"): (typeof documents)["\n  query GetProjects {\n    getProjects {\n      projects {\n        id\n        projectName\n        websiteAddress\n      }\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetSubmissionDataResolver($projectId: String, $dateRange: String, $defaultProjectId: String) {\n  getSubmissionDataResolver(projectId: $projectId, dateRange: $dateRange, defaultProjectId: $defaultProjectId) {\n    submissions {\n      id\n      formId\n      createdAt\n      submissionData\n    }\n    canAccessFiles\n    totalStorageUsed\n    totalSubmissions\n  }\n}"): (typeof documents)["\n  query GetSubmissionDataResolver($projectId: String, $dateRange: String, $defaultProjectId: String) {\n  getSubmissionDataResolver(projectId: $projectId, dateRange: $dateRange, defaultProjectId: $defaultProjectId) {\n    submissions {\n      id\n      formId\n      createdAt\n      submissionData\n    }\n    canAccessFiles\n    totalStorageUsed\n    totalSubmissions\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation ProjectFormCreation($data: CreateFormInput!) {\n    projectFormCreation(data: $data) {\n      data {\n        id\n        name\n        projectId\n      }\n      message\n    }\n  }\n"): (typeof documents)["\nmutation ProjectFormCreation($data: CreateFormInput!) {\n    projectFormCreation(data: $data) {\n      data {\n        id\n        name\n        projectId\n      }\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation DeleteForm($data: DeleteFormInput!) {\n  deleteForm(data: $data) {\n    message\n  }\n}"): (typeof documents)["\nmutation DeleteForm($data: DeleteFormInput!) {\n  deleteForm(data: $data) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation EditForm($data: EditFormInput!) {\n  editForm(data: $data) {\n    message\n  }\n}"): (typeof documents)["\n  mutation EditForm($data: EditFormInput!) {\n  editForm(data: $data) {\n    message\n  }\n}"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;