import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { debounce, DebouncedFunc } from 'lodash';
import { useState } from 'react';

export type SearchProps = {
  name?: string;
  // eslint-disable-next-line no-unused-vars
  getData: (text: string) => void;
  disabled: boolean;
};

// eslint-disable-next-line no-unused-vars
let searchDebounce: DebouncedFunc<(value: string) => void> | null = null;

const SearchComponent = (props: SearchProps) => {
  const [query, setQuery] = useState<string>('');
  const { name = '', getData, disabled, ...rest } = props;

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(value);
    if (getData) {
      if (searchDebounce) {
        searchDebounce.cancel();
        searchDebounce = null;
      }
      // Pass the value to getData
      searchDebounce = debounce((val: string) => getData(val), 800);
      searchDebounce(value);
    }
  };

  return (
    <Input
      className="search-component"
      allowClear
      placeholder={`Search ${name}`}
      value={query}
      name={name}
      onChange={handleChange}
      suffix={<SearchOutlined />}
      disabled={disabled}
      {...rest}
    />
  );
};

export default SearchComponent;
