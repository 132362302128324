import { InfoCircleOutlined } from '@ant-design/icons';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { Button, Form, FormInstance, Input, Select, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import {
  CreateFormInput,
  Project,
  ProjectForms,
  ProjectFormsQuery,
} from '../../__generated__/graphql';
import { MESSAGE, ROUTES } from '../../common/constants';
import { formItemProps, formValidatorRules } from '../../common/utils';
import { messageContext } from '../../components/AppContextHolder';
import CommonModal from '../../components/modals/CommonModal';
import { AppContextType } from '../../types/appContext.type';
import { CREATE_FORM, EDIT_FORMS } from './graphql/mutation';
const { Text, Title } = Typography;

const { required } = formValidatorRules;

type CreateFormModalType = {
  open: boolean;
  showFooter: boolean;
  closable: boolean;
  onCancel: () => void;
  editFormData?: ProjectForms;
  form: FormInstance;
  refetch: () => Promise<ApolloQueryResult<ProjectFormsQuery>>;
  maskClosable: boolean;
};
const CreateFormModal = ({
  open,
  showFooter,
  closable,
  onCancel,
  editFormData,
  form,
  refetch,
}: CreateFormModalType) => {
  const { state } = useContext(AppContext) as AppContextType;
  const { projects, projectId, subscriptionPlans, members } = state;
  const activePlan = subscriptionPlans?.planName;
  const isFreePlan = activePlan === 'FREE';
  const [selectedProject, setSelectedProject] = useState<Project>();

  const [formCreateMutation, { loading: createFormLoad }] = useMutation(
    CREATE_FORM,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [editForm, { loading: editFormLoad }] = useMutation(EDIT_FORMS, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    if (projectId && projects) {
      const selected = projects.find((project) => project.id === projectId);
      setSelectedProject(selected);
    }
  }, [projectId, projects]);

  useEffect(() => {
    if (editFormData) {
      form.setFieldsValue({
        formName: editFormData?.name,
      });
    }
  }, [editFormData, form]);

  const onFinish = async (values: CreateFormInput) => {
    try {
      if (editFormData?.id) {
        await editForm({
          variables: {
            data: {
              formName: values.formName,
              formId: editFormData.id,
              notificationEmail: values.notificationEmail,
            },
          },
        });

        await refetch();
        form.resetFields();
        onCancel();
      } else {
        await formCreateMutation({
          variables: {
            data: {
              formName: values?.formName,
              projectId: selectedProject?.id || '',
              notificationEmail: values?.notificationEmail,
            },
          },
        });
        await refetch();
        form.resetFields();
        onCancel();
      }
    } catch (error) {
      messageContext.error('Something went wrong!');
    }
  };

  const member =
    members?.projectMembers?.map((member) => ({
      id: member?.user.id,
      label: member?.user?.email,
      value: member?.user?.email,
    })) || [];

  return (
    <>
      <CommonModal
        open={open}
        showFooter={showFooter}
        closable={closable}
        onCancel={onCancel}
        maskClosable={false}
      >
        <div className="create-forms-form m-8">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              notificationEmail: member && member[0]?.value,
            }}
            preserve={false}
          >
            <Title level={3}>
              {editFormData?.id ? 'Update Form' : 'Create Form'}
            </Title>
            <Form.Item label="Project" name="projectId" required={true}>
              {selectedProject && (
                <Input
                  placeholder="Project"
                  disabled={true}
                  value={selectedProject?.id ?? undefined}
                  defaultValue={selectedProject.projectName ?? undefined}
                />
              )}
            </Form.Item>
            <Form.Item
              label="Form Name"
              name="formName"
              rules={[{ ...required, message: MESSAGE?.required }]}
              normalize={formItemProps.normalize}
            >
              <Input placeholder="Enter form name" />
            </Form.Item>
            <Form.Item
              label="Send notification for incoming submissions to"
              name="notificationEmail"
              rules={[{ ...required, message: MESSAGE?.required }]}
            >
              <Select
                onChange={(value) =>
                  form.setFieldsValue({ emailNotify: value })
                }
                disabled={isFreePlan}
                value={member && member[0]?.value}
                options={
                  isFreePlan
                    ? [
                        {
                          id: member[0]?.id,
                          label: member[0]?.label,
                          value: member[0]?.value,
                        },
                      ]
                    : member
                }
              />
              {isFreePlan && (
                <div>
                  <Text>
                    <InfoCircleOutlined /> Upgrade to paid plan in order to add
                    a different email address.{' '}
                  </Text>
                  <Link to={ROUTES?.PLANS}>know more.</Link>
                </div>
              )}
            </Form.Item>
            <Form.Item className="d-flex justify-end">
              <Button
                type="primary"
                htmlType="submit"
                loading={createFormLoad ? createFormLoad : editFormLoad}
              >
                {editFormData?.id ? 'Update' : 'Create'}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </CommonModal>
    </>
  );
};

export default CreateFormModal;
