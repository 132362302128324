import { gql } from '../../../__generated__';

export const PROJECT_FORMS = gql(`
query ProjectForms($where: ProjectFormsDataInput!, $filters: ProjectFormsFilters) {
    projectForms(where: $where, filters: $filters) {
      projectName
      projectForms {
        createdAt
        name
        submissionCount
        id
      }
      emptyStateMessage
      errorMessage
      formCountString
      submissionCountString
    }
  }
`);

export const PROJECT_MEMBER = gql(`
query ProjectMembers($where: ProjectMemberDataInput!, $filters: ProjectMembersFilters) {
  projectMembers(where: $where, filters: $filters) {
    projectName
    memberCountString
    errorMessage
    projectMembers {
      id
      role
      invitationStatus
      status
      user {
        email
        id
        firstName
        lastName
        profileImage
        role
      }
    }
  }
}`);

export const GET_PROJECT = gql(`
  query GetProjects {
    getProjects {
      projects {
        id
        projectName
        websiteAddress
      }
    }
  }`);

export const SUBMISSIONS_DATA = gql(`
  query GetSubmissionDataResolver($projectId: String, $dateRange: String, $defaultProjectId: String) {
  getSubmissionDataResolver(projectId: $projectId, dateRange: $dateRange, defaultProjectId: $defaultProjectId) {
    submissions {
      id
      formId
      createdAt
      submissionData
    }
    canAccessFiles
    totalStorageUsed
    totalSubmissions
  }
}`);
