import { UserOutlined } from '@ant-design/icons';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Spin, Statistic, Typography } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { ForgotPasswordInput } from '../../__generated__/graphql';
import { LwForms } from '../../assets/svg';
import { COMPANY_INFO, ROUTES } from '../../common/constants';
import {
  formItemProps,
  formValidatorRules,
  getCookie,
  setCookie,
} from '../../common/utils';
import FooterContent from '../../components/FooterContent';
import useRouter from '../../hooks/useRouter';
import { FORGOT_PASSWORD } from './graphql/mutations';
import { useMutation } from '@apollo/client';
import { messageContext } from '../../components/AppContextHolder';
const { Title, Text } = Typography;

const { required, email } = formValidatorRules;
const { Countdown } = Statistic;

const ForgetPassword = () => {
  const { navigate } = useRouter();
  const [count, setCount] = useState<number>(
    getCookie('forgotPasswordLink') ? 2 : 1,
  );
  const [forgotLinkExpire, setForgotLinkExpire] = useState<boolean>(false);
  const deadline = getCookie('forgotPasswordLink');
  const forgotPasswordEmailID = getCookie('forgotEmailId');
  const [forgetPasswordMutating, { loading: forgotloading }] = useMutation(
    FORGOT_PASSWORD,
    {
      onError() {}, // Always write this method for error handling in all mutation.
    },
  );
  const handleBack = () => {
    setCount(count - 1);
  };
  const handleResendLink = () => {
    setForgotLinkExpire(true);
    const time = dayjs()
      ?.add(COMPANY_INFO?.VERIFICATION_LINK_COOKIE_EXPIRY, 'minutes')
      ?.format();
    setCookie(
      'forgotPasswordLink',
      time,
      COMPANY_INFO.VERIFICATION_LINK_COOKIE_EXPIRY,
    );
  };
  const onFinish = (values: ForgotPasswordInput) => {
    try {
      if (!values?.email) {
        return;
      }
      setCount(count + 1);
      setForgotLinkExpire(true);
      const time = dayjs()
        ?.add(COMPANY_INFO?.VERIFICATION_LINK_COOKIE_EXPIRY, 'minutes')
        ?.format();
      setCookie(
        'forgotPasswordLink',
        time,
        COMPANY_INFO.VERIFICATION_LINK_COOKIE_EXPIRY,
      );
      setCookie('forgotEmailId', values?.email, COMPANY_INFO.COOKIE_EXPIRY);
      const formValues = {
        email: values?.email.toLowerCase(),
      };
      forgetPasswordMutating({
        variables: { data: { ...formValues } },
      });
    } catch (error) {
      messageContext.error('Faild');
    }
  };
  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-center">
        <Card className="full-width">
          <Spin spinning={forgotloading} wrapperClassName="full-width">
            {count === 1 && (
              <>
                <div className="text-center m-16">
                  <LwForms />
                  <Title level={4} className="text-center m-8">
                    Forgot Your Password?
                  </Title>
                  <Text className="text-center m-8">
                    Don't worry. Recovering the password is easy. Just tell us
                    the email.
                  </Text>
                </div>

                <Form layout="vertical" onFinish={onFinish} size="large">
                  <Form.Item
                    name="email"
                    rules={[
                      { ...required, message: 'Please enter email!' },
                      email,
                    ]}
                    normalize={formItemProps.normalize}
                  >
                    <Input
                      prefix={<UserOutlined />}
                      placeholder="Enter email"
                    />
                  </Form.Item>
                  <Form.Item className="full-width mb-8">
                    <Button
                      type="primary"
                      className="full-width"
                      htmlType="submit"
                    >
                      Reset Password
                    </Button>
                  </Form.Item>
                  <Form.Item className="text-center mb-0">
                    <Button
                      type="link"
                      onClick={() => {
                        navigate(ROUTES?.LOGIN);
                      }}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </>
            )}
            {count === 2 && (
              <>
                <ArrowLeftOutlined onClick={handleBack} />
                <h2 className="resend-heading">Reset Link Sent</h2>
                <div className="email-sent-description">
                  <p>
                    A link to reset your password has been sent to{' '}
                    {forgotPasswordEmailID}.Please check your inbox.
                  </p>
                </div>
                <Button
                  className="full-width p-0 m-10 resend-btn"
                  disabled={forgotLinkExpire}
                  onClick={handleResendLink}
                >
                  <div className="countdown-container d-flex justify-center">
                    <span className="countdown-title">
                      {deadline ? 'Resend in:' : 'Resend Link'}
                    </span>
                    {deadline && (
                      <div className="countdown">
                        <Countdown
                          format="mm:ss"
                          className="countdown"
                          value={deadline}
                          onFinish={() => {
                            setForgotLinkExpire(false);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </Button>
              </>
            )}
          </Spin>
        </Card>
        <FooterContent />
      </div>
    </div>
  );
};

export default ForgetPassword;
