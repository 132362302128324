import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Space, Spin, Statistic } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { COMPANY_INFO, ROUTES } from '../../common/constants';
import { getCookie, setCookie } from '../../common/utils';
import FooterContent from '../../components/FooterContent';
import useRouter from '../../hooks/useRouter';
const { Countdown } = Statistic;

const VerificationLink = () => {
  const { navigate } = useRouter();
  const signupLoading = false;
  const [timeStamp, setTimeStamp] = useState<boolean>(false);

  const handleBack = () => {
    navigate(-1);
  };
  const deadline = getCookie('resendLink');
  const emailId = getCookie('emailId');

  const handleResendLink = () => {
    const time = dayjs()
      ?.add(COMPANY_INFO?.VERIFICATION_LINK_COOKIE_EXPIRY, 'minutes')
      ?.format();
    setCookie('resendLink', time, COMPANY_INFO.VERIFICATION_LINK_COOKIE_EXPIRY);
    setTimeStamp(true);
  };
  useEffect(() => {
    // const resendLink = getCookie('resendLink');
    setTimeStamp(true);
  }, []);
  return (
    <>
      <div className="auth-bg">
        <div className="login-wrap d-flex align-center justify-start">
          <Card className="full-width">
            <Spin spinning={signupLoading} wrapperClassName="full-width">
              <ArrowLeftOutlined onClick={handleBack} />
              <h2 className="resend-heading">Email Sent</h2>
              <div className="email-sent-description">
                <p>
                  An Email containing your verification link is on its way.
                  Please check your inbox, {emailId}. Don't forget to check your
                  spam and junk folders.
                </p>
              </div>
              <Button
                className="full-width p-0 m-10 resend-btn"
                disabled={timeStamp}
                onClick={handleResendLink}
              >
                <div className="countdown-container d-flex justify-center">
                  <span className="countdown-title">
                    {deadline ? 'Resend in: ' : 'Resend Link'}
                  </span>
                  {deadline && (
                    <div className="countdown">
                      <Countdown
                        format="mm:ss"
                        className="countdown"
                        value={deadline}
                        onFinish={() => {
                          setTimeStamp(false);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Button>
              <div className="resend-to-login text-center">
                <Space>
                  Already have an account ?
                  <Button
                    type="link"
                    className="p-0"
                    onClick={() => {
                      navigate(ROUTES?.LOGIN);
                    }}
                  >
                    Login
                  </Button>
                </Space>
              </div>
            </Spin>
          </Card>
          <FooterContent />
        </div>
      </div>
    </>
  );
};
export default VerificationLink;
