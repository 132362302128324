import { useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import 'firebase/auth';
import 'firebase/functions';
import { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { SignUpInputData, User } from '../../__generated__/graphql';
import { Google, LwForms, Microsoft } from '../../assets/svg';
import { COMPANY_INFO, MESSAGE, REGEX, ROUTES } from '../../common/constants';
import {
  formItemProps,
  formValidatorRules,
  setCookie,
} from '../../common/utils';
import { messageContext } from '../../components/AppContextHolder';
import FooterContent from '../../components/FooterContent';
import {
  signInWithGooglePopup,
  signInWithMicrosoftPopup,
} from '../../firebase/collection/auth';
import useRouter from '../../hooks/useRouter';
import { AppContextType } from '../../types/appContext.type';
import { FIREBASE_LOGIN, SIGN_UP } from './graphql/mutations';
const { required, email } = formValidatorRules;
const { Title } = Typography;

const Signup = () => {
  const [form] = Form.useForm();
  const { navigate } = useRouter();
  const { initializeAuth } = useContext(AppContext) as AppContextType;

  const [signUpMutate, { loading }] = useMutation(SIGN_UP, {
    fetchPolicy: 'network-only',
    onError: (error) => {
      messageContext.error(error.message);
    },
  });

  const [firebaseLoginMutate] = useMutation(FIREBASE_LOGIN, {
    fetchPolicy: 'network-only',
    onError: (error) => {
      messageContext.error(error.message);
    },
  });

  function successCallback(
    accessToken: string | null,
    userData: User,
    refreshToken?: string | null,
  ) {
    initializeAuth(accessToken, userData, refreshToken);
    navigate(ROUTES.MAIN);
  }

  const handleGoogleSignIn = async () => {
    try {
      const { user } = await signInWithGooglePopup();

      if (user) {
        const { token } = await user.getIdTokenResult();

        await firebaseLoginMutate({
          variables: { data: { token: token } },
          onError: () => {},
          onCompleted: (res) => {
            if (res?.firebaseLogin) {
              const userData = res?.firebaseLogin?.data;
              const accessToken = res?.firebaseLogin?.accessToken;
              const refreshToken = res?.firebaseLogin?.refreshToken;
              if (successCallback) {
                successCallback(accessToken!, userData!, refreshToken);
              }
            } else {
              messageContext.error('Something went wrong');
            }
          },
        });
      }
    } catch (error) {
      messageContext.error('An error occurred:');
    }
  };

  const handleMicrosoftSignIn = async () => {
    // Handle Microsoft sign in logic
    try {
      await signInWithMicrosoftPopup();

      // if (token) handleGoogleFinish(token);
    } catch (error) {
      // messageContext?.error(error?.message || 'Something went wrong!');
    }
  };
  const onFinishSignUp = async (values: SignUpInputData) => {
    form.resetFields();
    try {
      if (values?.password === values?.confirmPassword) {
        const formValues = {
          email: values?.email?.toLowerCase(),
          firstName: values?.firstName,
          lastName: values?.lastName,
          password: values?.password,
          confirmPassword: values?.confirmPassword,
        };
        signUpMutate({
          variables: { data: { ...formValues } },
          onError: (error) => {
            messageContext.error(error.message);
          },
          onCompleted: () => {
            navigate(`${ROUTES?.RESEND_LINK}`);
            const time = dayjs()
              ?.add(COMPANY_INFO?.VERIFICATION_LINK_COOKIE_EXPIRY, 'minutes')
              ?.format();
            setCookie(
              'resendLink',
              time,
              COMPANY_INFO.VERIFICATION_LINK_COOKIE_EXPIRY,
            );
            setCookie('emailId', values?.email, COMPANY_INFO.COOKIE_EXPIRY);
          },
        });
      } else {
        messageContext.error('Password can not match with confirm password');
      }
    } catch (error) {
      messageContext.error('An error occurred. Please try again later.');
    }
  };
  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-start">
        <Card className="full-width">
          <Spin spinning={loading} wrapperClassName="full-width">
            <div className="text-center m-16">
              <LwForms />
              <Title level={4}>Create your account</Title>
            </div>
            <Form
              form={form}
              name="SignUp"
              initialValues={{ remember: true }}
              onFinish={onFinishSignUp}
              size="large"
            >
              <Form.Item
                name="email"
                rules={[{ ...required, message: MESSAGE?.required }, email]}
                hasFeedback
                normalize={formItemProps.normalize}
              >
                <Input placeholder="Enter email" />
              </Form.Item>
              <div>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="firstName"
                      rules={[{ ...required, message: MESSAGE?.required }]}
                      normalize={formItemProps.normalize}
                    >
                      <Input placeholder="Enter FirstName" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="lastName"
                      rules={[{ ...required, message: MESSAGE?.required }]}
                      normalize={formItemProps.normalize}
                    >
                      <Input placeholder="Enter LastName" />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Form.Item
                name="password"
                rules={[
                  {
                    ...required,
                    message: MESSAGE?.required,
                  },
                  {
                    pattern: REGEX.PASSWORD,
                    message:
                      'Password must be at least 8 characters long that includes 1 number, and 1 special character',
                  },
                ]}
                hasFeedback
                normalize={formItemProps.normalize}
              >
                <Input.Password placeholder="Enter password" />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                rules={[
                  { ...required, message: MESSAGE?.required },

                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value !== getFieldValue('password')) {
                        return Promise?.reject(
                          new Error('Passwords do not match'),
                        );
                      }
                      return Promise?.resolve();
                    },
                  }),
                ]}
                hasFeedback
                normalize={formItemProps.normalize}
              >
                <Input.Password placeholder="Enter confirm password" />
              </Form.Item>
              <Form.Item className="full-width mb-8">
                <Button type="primary" className="full-width" htmlType="submit">
                  SignUp
                </Button>
              </Form.Item>
              <div className="full-width continue-btn">
                <Button
                  type="default"
                  icon={<Google />}
                  onClick={handleGoogleSignIn}
                  className="full-width custom-button"
                >
                  Continue with Google
                </Button>
                <Button
                  type="default"
                  icon={<Microsoft />}
                  onClick={handleMicrosoftSignIn}
                  className="full-width custom-button"
                >
                  Continue with Microsoft
                </Button>
              </div>
              <Form.Item className="text-center mb-0">
                <Space>
                  Already have an account ?
                  <Button
                    type="link"
                    className="p-0"
                    onClick={() => {
                      navigate(ROUTES?.LOGIN);
                    }}
                  >
                    Login
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
        <FooterContent />
      </div>
    </div>
  );
};

export default Signup;
