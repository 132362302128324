import {
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Button, Layout, Select } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { BREAKPOINTS, INVITATION_STATUS, ROUTES } from '../common/constants';
import { AppContext } from '../AppContext';
import { ActiveSubscriptionDetails, Project } from '../__generated__/graphql';
import MobileLogoComponent from '../components/MobileLogoComponent';
import { ACTIVE_SUBSCRIPTION_PLAN } from '../modules/auth/graphql/queries';
import { GET_PROJECT } from '../modules/dashboard/graphql/Queries';
import { AppActionType, AppContextType } from '../types/appContext.type';
import AppHeader from './components/header/AppHeader';
import UserProfile from './components/header/UserProfile';
import Sidebar from './components/sidebar/Sidebar';

import { PROJECT_MEMBER } from '../modules/dashboard/graphql/Queries';
import { ProjectWithMembersResponse } from '../__generated__/graphql';

const { Content, Sider } = Layout;
const App = () => {
  const [isDesktop, setDesktop] = useState(
    window.innerWidth > BREAKPOINTS.tablet,
  );
  const { state, dispatch } = useContext(AppContext) as AppContextType;
  const [isActive, setActive] = useState(false);
  const { projects, projectId } = state;

  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(
    null,
  );

  const { loading: loadProjects } = useQuery(GET_PROJECT, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted: (res) => {
      if (res?.getProjects) {
        const projects = res?.getProjects?.projects as Project[];
        const firstProjectId = projects?.[0]?.id ?? null;

        dispatch({
          type: AppActionType?.setProject,
          data: projects,
        });
        dispatch({
          type: AppActionType?.setProjectId,
          data: firstProjectId,
        });
        setSelectedProjectId(firstProjectId);
      }
    },
  });

  const [activeSubscriptionPlans] = useLazyQuery(ACTIVE_SUBSCRIPTION_PLAN, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted: (res) => {
      if (res) {
        const userPlans = res?.getActiveSubscription;
        dispatch({
          type: AppActionType?.setActiveSubscriptionPlans,
          data: userPlans as ActiveSubscriptionDetails,
        });
      }
    },
  });
  const [projectMembers] = useLazyQuery(PROJECT_MEMBER, {
    fetchPolicy: 'network-only',
    onError: () => {},
    onCompleted: (res) => {
      if (res?.projectMembers) {
        const projectMemberData =
          res?.projectMembers as ProjectWithMembersResponse;

        dispatch({
          type: AppActionType?.setMembers,
          data: projectMemberData,
        });
      }
    },
  });
  useEffect(() => {
    if (projectId) {
      projectMembers({
        variables: {
          where: {
            projectId: projectId,
          },
          filters: {
            invitationStatus: INVITATION_STATUS?.ACCEPTED,
          },
        },
      });
    }
  }, [projectId, projectMembers]);

  useEffect(() => {
    if (projectId) {
      activeSubscriptionPlans({
        variables: {
          projectId: projectId,
        },
      });
    }
  }, [projectId]);

  const handleOverlay = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    const updateMedia = () => {
      setDesktop(window.innerWidth > BREAKPOINTS.tablet);
    };
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const projectOptions = projects?.map((project) => ({
    id: project?.id,
    value: project?.id,
    label: project?.projectName,
  }));

  const handleChange = (value: string | null | undefined) => {
    const selectedProject = projectOptions?.find(
      (project) => project?.value === value,
    );
    if (selectedProject && selectedProject?.id !== undefined) {
      setSelectedProjectId(selectedProject?.id);
      dispatch({
        type: AppActionType.setProjectId,
        data: selectedProject?.id,
      });
    } else {
      setSelectedProjectId(null);
    }
  };

  const [collapsed, setCollapsed] = useState(false);
  return (
    <Layout hasSider>
      {!isDesktop && (
        <span
          className={isActive ? 'active overlay-responsive' : 'overlay-disable'}
          onClick={handleOverlay}
        />
      )}
      <Sider
        theme="light"
        collapsed={isDesktop ? collapsed : false}
        className={isActive ? 'close' : ''}
        breakpoint="md"
      >
        <Link id="logo" to={ROUTES?.MAIN}>
          {collapsed ? (
            <MobileLogoComponent className="mr-0" />
          ) : (
            <>
              <MobileLogoComponent className="mr-8" />
              LWForms
            </>
          )}
        </Link>
        <Sidebar setActive={setActive} />
      </Sider>
      <Layout className="site-layout">
        <AppHeader>
          <div className="header-wrapper">
            {isDesktop ? (
              <>
                {collapsed ? (
                  <MenuUnfoldOutlined
                    className="trigger"
                    onClick={() => setCollapsed(!collapsed)}
                  />
                ) : (
                  <MenuFoldOutlined
                    className="trigger"
                    onClick={() => setCollapsed(!collapsed)}
                  />
                )}
              </>
            ) : (
              <>
                <Button
                  className="trigger"
                  type="text"
                  onClick={handleOverlay}
                  icon={<MenuOutlined />}
                  size="middle"
                />
                <div className="responsive-logo text-center">
                  <MobileLogoComponent />
                </div>
              </>
            )}
            <div className="d-flex align-items-center">
              {projects && projects?.length > 0 ? (
                <div className="forms-select-component m-16 d-flex">
                  <Select
                    defaultValue={projectOptions && projectOptions[0]?.value}
                    value={
                      projectOptions?.find((p) => p?.id === selectedProjectId)
                        ?.value
                    }
                    onChange={handleChange}
                    loading={loadProjects}
                    options={projectOptions}
                  />
                </div>
              ) : (
                ''
              )}
              <UserProfile />
            </div>
          </div>
        </AppHeader>
        <Content className="wrapper">
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
