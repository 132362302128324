import { Modal } from 'antd';

type ModalType = {
  title?: string;
  open: boolean;
  okText?: string;
  onOk?: () => void;
  onCancel?: () => void;
  showFooter?: boolean;
  closable?: boolean;
  children: React.ReactNode;
  maskClosable?: boolean;
};
const CommonModal = (props: ModalType) => {
  const {
    open = false,
    onOk,
    onCancel,
    title,
    children,
    okText = 'OK',
    showFooter = true,
    closable = true,
    maskClosable = true,
    ...rest
  } = props;
  return (
    <Modal
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      title={title}
      okText={okText}
      destroyOnClose
      footer={showFooter ? undefined : null}
      closable={closable}
      maskClosable={maskClosable}
      {...rest}
    >
      {children}
    </Modal>
  );
};

export default CommonModal;
