import { Table } from 'antd';
import { TableProps } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { PaginationConfig } from '../types/common.type';

const TableComponent = <T extends object>(props: TableProps<T>) => {
  const {
    columns,
    dataSource = [],
    onChange,
    loading = false,
    rootClassName = '',
    pagination,
    scroll,
    sticky,
    ...rest
  } = props;

  const [paginationProps, setPaginationProps] = useState<PaginationConfig>({
    pageSizeOptions: [10, 15, 20, 50, 100],
    defaultPageSize: 10,
    responsive: true,
    showSizeChanger: true,
    position: ['bottomRight'],
  });

  useEffect(() => {
    if (pagination) {
      setPaginationProps({
        ...paginationProps,
        ...pagination,
      } as PaginationConfig);
    }
  }, [pagination]);

  return (
    <Table
      columns={columns}
      bordered={false}
      dataSource={dataSource}
      className={rootClassName}
      onChange={onChange}
      pagination={pagination ? paginationProps : false}
      loading={loading}
      scroll={scroll}
      sticky={sticky}
      {...rest}
    />
  );
};

export default TableComponent;
