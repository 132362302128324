import { gql } from '../../../__generated__';

export const GET_CURRENT_USER = gql(`query GetCurrentUser {
  getCurrentUser {
    email
    id
    firstName
    lastName
    profileImage
    role
  }
}`);

export const UPLOAD_FILE = gql(`
query GetProfileImageUploadSignedUrl($data: GetProfileImageUploadSignedUrlInput) {
  getProfileImageUploadSignedUrl(data: $data) {
    key
    signedUrl
  }
}`);

export const ACTIVE_SUBSCRIPTION_PLAN = gql(`
 query GetActiveSubscription($projectId: String!) {
  getActiveSubscription(projectId: $projectId) {
    planName
    status
    features
    autoRenewalDate
    renewalAmount
    paymentMethods
    billingHistory {
      amount
      description
      createdAt
    }
  }
}`);
