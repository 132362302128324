import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const googleProvider = new GoogleAuthProvider(); // Remove 'app' from here
const microsoftProvider = new OAuthProvider('microsoft.com');

googleProvider.setCustomParameters({
  prompt: 'select_account',
});

microsoftProvider.setCustomParameters({
  prompt: 'select_account',
});

const auth = getAuth(app);

export { app, auth, googleProvider, microsoftProvider }; // Exporting 'app', 'auth', and 'googleProvider'
