import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  GetProp,
  Image,
  Input,
  Progress,
  Row,
  Space,
  Spin,
  Statistic,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import {
  fileUpload,
  formItemProps,
  formValidatorRules,
  getBase64,
  handleProtectedNavigation,
} from '../../common/utils';
import './Profile.less';
import RouterPrompt from '../../components/RouterPrompt';
import useRouter from '../../hooks/useRouter';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import { AppContext } from '../../AppContext';
import { GET_CURRENT_USER, UPLOAD_FILE } from '../auth/graphql/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  CHANGE_PASSWORD,
  UPDATE_CURRENT_USER,
} from '../auth/graphql/mutations';
import {
  ChangePasswordInput,
  SignedUrlResponse,
  UpdateUserInput,
} from '../../__generated__/graphql';
import { messageContext } from '../../components/AppContextHolder';
import CommonModal from '../../components/modals/CommonModal';
import { REGEX, ROUTES } from '../../common/constants';
import { PlusOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { PROJECT_FORMS } from '../dashboard/graphql/Queries';
import LoaderComponent from '../../components/LoaderComponent';
const { Text } = Typography;

const { required, name, email } = formValidatorRules;
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

function Profile() {
  const { getToken, initializeAuth, dispatch, state } = useContext(
    AppContext,
  ) as AppContextType;

  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [isPrompt, setIsPrompt] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [changePasswordForm] = Form.useForm<ChangePasswordInput>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [imageUploading, setImageUploading] = useState<boolean>(false);
  const [submissionPercentage, setSubmissionPercentage] = useState(0);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const [form] = Form.useForm<UpdateUserInput>();
  const { navigate } = useRouter();
  const idToken = getToken();
  const { submissions, projectId } = state;

  const { data: userData, loading: profileLoading } = useQuery(
    GET_CURRENT_USER,
    {
      fetchPolicy: 'network-only',
      onError: (error) => {
        messageContext.error(error.message);
      },
    },
  );
  useEffect(() => {
    if (userData?.getCurrentUser?.profileImage) {
      setFileList([
        {
          uid: '-1',
          name: 'profileImage',
          status: 'done',
          url: userData?.getCurrentUser?.profileImage,
        },
      ]);
    }
  }, [userData]);

  const [changePassword, { loading: changePasswordLoad }] = useMutation(
    CHANGE_PASSWORD,
    {
      fetchPolicy: 'network-only',
      onError: (error) => {
        messageContext.error(error.message);
      },
    },
  );

  const [updateCurrentUser, { loading: updateUserLoad }] = useMutation(
    UPDATE_CURRENT_USER,
    {
      onCompleted: (res) => {
        if (res?.updateUser?.data) {
          dispatch({
            type: AppActionType.setCurrentUser,
            data: res?.updateUser?.data,
          });
          initializeAuth(idToken, res?.updateUser?.data);
        }
      },
      onError: () => {
        form.setFieldsValue(userData as UpdateUserInput);
        setBtnLoading(false);
      },
    },
  );

  const [getProfileImageUploadSignedUrl] = useLazyQuery(UPLOAD_FILE, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted: () => {},
  });

  const { data, loading: loadData } = useQuery(PROJECT_FORMS, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        projectId: projectId as string,
      },
    },
    onError() {},
  });

  useEffect(() => {
    if (data && data.projectForms && data.projectForms.submissionCountString) {
      const submissionCountString = data.projectForms.submissionCountString;
      const [currentStr, totalStr] = submissionCountString.split('/');

      const current = parseInt(currentStr, 10);
      const total = parseInt(totalStr, 10);
      const percentage = total === 0 ? 0 : (current / total) * 100;
      setSubmissionPercentage(percentage);
    }
  }, [data]);
  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    // Ensure only one file is in the list
    setFileList(newFileList.slice(-1));
  };

  const handleRemove = () => {
    setFileList([]);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    changePasswordForm.resetFields();
  };

  const handleBack = () => {
    setIsPrompt(!handleProtectedNavigation(!showPrompt, navigate, -1));
  };

  const handleShowPrompt = () => {
    setShowPrompt(true);
  };

  const handleOk = () => {
    handleProtectedNavigation(true, navigate, -1);
  };

  const handleClose = () => {
    setIsPrompt(false);
  };

  const handleModalOk = () => {
    changePasswordForm.submit();
  };

  const handleUpdate = async (values: ChangePasswordInput) => {
    try {
      await changePassword({
        variables: {
          data: {
            oldPassword: values?.oldPassword,
            newPassword: values?.newPassword,
          },
        },
        onCompleted: (response) => {
          if (response) {
            setVisible(false);
            messageContext.success(response?.changePassword?.message);
            navigate(ROUTES?.PROFILE, { replace: true });
          }
        },
      });
    } catch (error) {
      messageContext.error('Change password error:');
    }
  };

  const onFinish = async (values: UpdateUserInput) => {
    try {
      const image = values?.profileImage as UploadProps;
      let imgKey;

      if (image && image?.fileList) {
        const name = image?.fileList[0].name;
        const ext = name?.substring(name?.lastIndexOf('.') + 1);
        const allowedExtensions = ['png', 'jpeg', 'jpg'];
        if (!allowedExtensions.includes(ext)) {
          messageContext.error(
            'Invalid file type. Please upload an image in png, jpeg, or jpg format.',
          );
          return;
        }
        const timestamp = Date?.now();
        const filename = name?.split('.')?.slice(0, -1)?.join('.');
        const newFilename = `${timestamp}_${filename}.${ext}`;
        const uuid = uuidv4();
        const fileKey = `persons/${uuid}/${newFilename}`;

        const file = fileList[0]?.originFileObj;

        if (fileKey) {
          setImageUploading(true);
          const res = await getProfileImageUploadSignedUrl({
            variables: { data: { fileName: fileKey } },
          });
          const { signedUrl, key } = res?.data
            ?.getProfileImageUploadSignedUrl as SignedUrlResponse;

          try {
            if (signedUrl && file) {
              await fileUpload(signedUrl, file);
              imgKey = key;
            }
          } catch (error) {
            messageContext.error('Something went wrong...!');
          } finally {
            setImageUploading(false);
          }
        }
      }
      if (!values.firstName || !values.lastName) {
        messageContext.error('Please enter your first name and last name.');
        return;
      }

      setBtnLoading(true);
      setShowPrompt(false);

      const userObj = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        profileImage: imgKey,
      };
      await updateCurrentUser({
        variables: {
          data: userObj,
        },
      });
      setBtnLoading(false);
    } catch (error) {
      messageContext.error('Profile update failed');
    }
  };
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = (await getBase64(
        file.originFileObj as FileType,
      )) as string;
    }

    setPreviewImage(file.url || file.preview || '');
    setPreviewOpen(true);
  };

  const isSaving = btnLoading || imageUploading || updateUserLoad;

  return (
    <>
      <Card
        className="ant-body-scroll"
        title="Profile"
        actions={[
          <div key="actionButton" className="text-right">
            <Space>
              <Button onClick={handleBack} disabled={btnLoading}>
                Cancel
              </Button>
              <Button
                type="primary"
                loading={isSaving}
                htmlType="submit"
                onClick={() => form.submit()}
              >
                Save
              </Button>
            </Space>
          </div>,
        ]}
      >
        <div className="card-body-wrapper ">
          <LoaderComponent spinning={profileLoading} />
          {userData?.getCurrentUser && (
            <Form
              form={form}
              className="sticky-action-form"
              onFieldsChange={handleShowPrompt}
              layout="vertical"
              initialValues={userData?.getCurrentUser}
              onFinish={onFinish}
            >
              <Spin spinning={profileLoading} wrapperClassName="full-width">
                <Row gutter={[16, 16]}>
                  <Col xs={24} lg={24} xl={24}>
                    <Form.Item name="profileImage">
                      <Upload
                        accept="image/x-png, image/jpeg, image/jpg"
                        beforeUpload={() => false}
                        className="upload-image-container"
                        listType="picture-card"
                        showUploadList={{
                          showPreviewIcon: true,
                          showRemoveIcon: true,
                        }}
                        onChange={handleChange}
                        onRemove={handleRemove}
                        onPreview={handlePreview}
                        fileList={fileList}
                        maxCount={1}
                        multiple={false}
                      >
                        {fileList.length >= 1 ? null : (
                          <div className="d-flex align-center justify-center flex-vertical upload-content">
                            <div>
                              <PlusOutlined />
                              <p>Upload</p>
                            </div>
                          </div>
                        )}
                      </Upload>
                      {previewImage && (
                        <Image
                          wrapperStyle={{ display: 'none' }}
                          preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) =>
                              setPreviewOpen(visible),
                            afterOpenChange: (visible) =>
                              !visible && setPreviewImage(''),
                          }}
                          src={previewImage}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={8} xl={8}>
                    <Form.Item
                      name="firstName"
                      label="First Name"
                      normalize={formItemProps?.normalize}
                      rules={[
                        { ...required, message: 'Please Enter First Name' },
                        name,
                      ]}
                    >
                      <Input placeholder="Enter First Name" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={8} xl={8}>
                    <Form.Item
                      name="lastName"
                      label="Last Name"
                      normalize={formItemProps?.normalize}
                      rules={[
                        { ...required, message: 'Please Enter Last Name' },
                        name,
                      ]}
                    >
                      <Input placeholder="Enter Last Name" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={8} xl={8}>
                    <Form.Item
                      name="email"
                      label="Email Id"
                      rules={[
                        { ...required, message: 'Please Enter Email' },
                        email,
                      ]}
                    >
                      <Input disabled placeholder="Enter Email" />
                    </Form.Item>
                  </Col>
                </Row>
                <div className="mb-24">
                  <Row gutter={[24, 24]}>
                    <Col xs={24} lg={24} xl={24}>
                      <Card>
                        <div className="submission-card d-flex justify-between">
                          <div className="d-grid">
                            <Text strong>Password</Text>
                            <Text>Update your account password</Text>
                          </div>
                          <div>
                            <Button
                              type="primary"
                              onClick={showModal}
                              loading={updateUserLoad}
                            >
                              Update Password
                            </Button>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div className="usage-data">
                  <Row gutter={[16, 16]}>
                    <Col xs={24} lg={12} xl={12}>
                      <Card>
                        <div className="submission-card">
                          <p>Submissions Usage</p>
                          <Divider />
                          <Statistic
                            title="Monthly Submissions"
                            loading={loadData}
                            value={
                              data?.projectForms?.submissionCountString ?? ''
                            }
                          />
                          <Progress percent={submissionPercentage} />
                        </div>
                      </Card>
                    </Col>
                    <Col xs={24} lg={12} xl={12}>
                      <Card>
                        <div className="submission-card">
                          <p>Storage Usage</p>
                          <Divider />
                          <Statistic
                            title="Monthly Storage Usage"
                            value={submissions?.totalStorageUsed}
                            suffix={'/500MB'}
                          />
                          <Progress percent={0} />
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Spin>
            </Form>
          )}
        </div>
      </Card>

      <RouterPrompt
        isPrompt={isPrompt}
        handleOK={handleOk}
        handleCancel={handleClose}
      />
      <CommonModal
        title="Change Password"
        open={visible}
        onOk={handleModalOk}
        onCancel={handleCancel}
        okText="Update Password"
      >
        <Spin spinning={changePasswordLoad} wrapperClassName="full-width">
          <Form
            form={changePasswordForm}
            name="change_password"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={handleUpdate}
          >
            <Form.Item
              name="oldPassword"
              label="Old Password"
              rules={[
                { required: true, message: 'Please input your old password!' },
                {
                  pattern: REGEX.PASSWORD,
                  message:
                    'Password must be at least 8 characters long that includes 1 number, and 1 special character',
                },
              ]}
              hasFeedback
              normalize={formItemProps.normalize}
            >
              <Input.Password placeholder="Enter Old password" />
            </Form.Item>
            <Form.Item
              name="newPassword"
              label="New Password"
              rules={[
                { required: true, message: 'Please input your new password!' },
                {
                  pattern: REGEX.PASSWORD,
                  message:
                    'Password must be at least 8 characters long that includes 1 number, and 1 special character',
                },
              ]}
              hasFeedback
              normalize={formItemProps.normalize}
            >
              <Input.Password placeholder="Enter New password" />
            </Form.Item>
          </Form>
        </Spin>
      </CommonModal>
    </>
  );
}
export default Profile;
