import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Alert,
  Badge,
  Button,
  Card,
  Form,
  Popconfirm,
  Space,
  Typography,
} from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import {
  ProjectForms,
  ProjectFormsResponse,
} from '../../__generated__/graphql';
import { ROUTES } from '../../common/constants';
import TableComponent from '../../components/CommonTable';
import SearchComponent from '../../components/SearchComponent';
import useRouter from '../../hooks/useRouter';
import { AppContextType } from '../../types/appContext.type';
import { PaginationConfig } from '../../types/common.type';
import { PROJECT_FORMS } from '../dashboard/graphql/Queries';
import CreateFormModal from './CreateFormModal';
import './forms.less';
import { DELETE_FORM } from './graphql/mutation';

const { Title } = Typography;

const Forms = () => {
  const { navigate } = useRouter();
  const [form] = Form.useForm();
  const [search, setSearch] = useState('');
  const [isFormModalOpen, setIsFormModalOpen] = useState<boolean>(false);
  const [formsData, setFormsData] = useState<ProjectFormsResponse>({});
  const [editFormData, setEditFormData] = useState<ProjectForms | undefined>();
  const { state } = useContext(AppContext) as AppContextType;
  const { projectId, subscriptionPlans } = state;
  const activePlan = subscriptionPlans?.planName;
  const isFreePlan = activePlan === 'FREE';

  const paginationProps = {
    pageSizeOptions: [10, 15, 20, 50, 100],
    defaultPageSize: 10,
    responsive: true,
    showSizeChanger: true,
    position: ['bottomRight'],
  } as PaginationConfig;

  const [getForms, { loading: formsLoad, refetch }] = useLazyQuery(
    PROJECT_FORMS,
    {
      fetchPolicy: 'network-only',
      onError() {},
      onCompleted: (res) => {
        if (res?.projectForms) {
          setFormsData(res?.projectForms);
        }
      },
    },
  );

  useEffect(() => {
    if (projectId) {
      getForms({
        variables: {
          where: {
            projectId: projectId as string,
          },
          filters: {
            name: search,
          },
        },
      });
    }
  }, [search, projectId]);

  const fetchFormsData = () => {
    if (projectId) {
      getForms({
        variables: {
          where: {
            projectId: projectId as string,
          },
          filters: {
            name: search,
          },
        },
      });
    }
  };

  const [deleteForm] = useMutation(DELETE_FORM, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      getForms({
        variables: {
          where: {
            projectId: projectId as string,
          },
          filters: {
            name: search,
          },
        },
      });
    },
    onError: () => {},
  });

  const getData = (value: string | undefined) => {
    if (value !== undefined) {
      setSearch(value);
    }
  };

  const handleFormModal = () => {
    setIsFormModalOpen(true);
  };
  const showModalEdit = (record: ProjectForms | undefined) => {
    setEditFormData(record);
    setIsFormModalOpen(true);
  };

  const confirmDelete = (id: string) => {
    deleteForm({ variables: { data: { formId: id } } });
  };

  const cancelModal = () => {
    setEditFormData(undefined);
    form.resetFields();
    setIsFormModalOpen(false);
    fetchFormsData();
  };
  const navigateToDetail = (id: string) => {
    navigate(`${ROUTES?.FORMS}/${id}`);
  };

  const columns: ColumnType<ProjectForms>[] = [
    {
      title: 'Form Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: (
        <span>
          Submissions <Badge count={formsData?.submissionCountString} />
        </span>
      ),
      dataIndex: 'submissionCount',
      key: 'submissionCount',
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdOn: string) => dayjs(createdOn).format('DD/MM/YY, h:mmA'),
    },
    {
      title: 'Action',
      width: 150,
      render: (record) => (
        <Space>
          <EyeOutlined
            key="view"
            onClick={() => navigateToDetail(record?.id)}
          />
          <EditOutlined key="edit" onClick={() => showModalEdit(record)} />
          <Popconfirm
            title={`Delete ?`}
            description="Are you sure to delete this Form?"
            onConfirm={() => confirmDelete(record?.id)}
            okText="Yes"
            cancelText="No"
            icon={<QuestionCircleOutlined key="question" />}
          >
            <DeleteOutlined key="delete" className="delete-icon" />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const filteredForms = formsData?.projectForms?.filter(
    (form) => form !== null,
  ) as ProjectForms[];

  const filteredData = filteredForms?.map((form) => ({
    ...form,
    key: form?.id,
  }));

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        Forms <Badge count={formsData?.formCountString} />
      </Title>
      <Card
        className="ant-body-scroll"
        title={
          <div className="d-flex justify-between align-center search-container">
            <SearchComponent
              name="form"
              getData={getData}
              disabled={isFreePlan}
            />
            <Button
              type="primary"
              className="create-form-btn ml-16"
              icon={<PlusCircleOutlined />}
              onClick={handleFormModal}
            >
              Create New Form
            </Button>
          </div>
        }
      >
        <div className="card-body-wrapper">
          {parseInt(formsData?.formCountString || '0', 10) === 0 ? (
            <div className="zero-form align-center text-center">
              {formsData?.emptyStateMessage && (
                <div className="zero-form-container">
                  <h1 className="zero-form-text mt-16">
                    {formsData?.emptyStateMessage}
                  </h1>
                  <Button
                    type="primary"
                    className="create-form-btn"
                    icon={<PlusCircleOutlined />}
                    onClick={handleFormModal}
                  >
                    Create New Form
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <>
              <div className="form-table-component p-16">
                <TableComponent<ProjectForms>
                  columns={columns}
                  loading={formsLoad}
                  dataSource={filteredData}
                  pagination={paginationProps}
                />
                {formsData?.errorMessage && (
                  <Alert
                    type="error"
                    showIcon
                    message={formsData?.errorMessage}
                  />
                )}
              </div>
            </>
          )}
        </div>
        {isFormModalOpen && (
          <CreateFormModal
            open={isFormModalOpen}
            showFooter={false}
            closable={true}
            onCancel={cancelModal}
            editFormData={editFormData}
            form={form}
            refetch={refetch}
            maskClosable={false}
          ></CreateFormModal>
        )}
      </Card>
    </>
  );
};

export default Forms;
