import { Button, Card, Spin, Typography } from 'antd';
import { ROUTES } from '../../common/constants';
import FooterContent from '../../components/FooterContent';
import useRouter from '../../hooks/useRouter';
import { useMutation } from '@apollo/client';
import { VERIFICATION } from './graphql/mutations';
import { useEffect, useState } from 'react';
import { messageContext } from '../../components/AppContextHolder';
const { Title, Text } = Typography;

const Confirmation = () => {
  const { navigate, location } = useRouter();
  const [verifyMessage, setVerifyMessage] = useState<string | null>();

  const params = new URLSearchParams(location?.search);
  const token = params?.get('token') || location?.state?.token;
  const uid = params?.get('uid') || location?.state?.uid;

  const [verifymutation, { loading: verifyLoading }] = useMutation(
    VERIFICATION,
    {
      fetchPolicy: 'network-only',
      onError: (error) => {
        messageContext.error(error.message);
      },
      onCompleted: (res) => {
        if (res) {
          setVerifyMessage(res.emailVerification?.message);
        }
      },
    },
  );

  useEffect(() => {
    verifymutation({
      variables: {
        data: {
          uid: uid,
          token: token,
        },
      },
    });
  }, []);

  const handleLink = () => {
    navigate(ROUTES.LOGIN);
  };

  return (
    <>
      <div className="auth-bg">
        <div className="login-wrap d-flex align-center justify-start">
          <Card className="full-width text-center">
            <Spin spinning={verifyLoading} wrapperClassName="full-width">
              <Title level={4}>{verifyMessage}</Title>
              <div className="email-sent-description">
                <Text>Welcome to LWForms</Text>
              </div>
              <Button
                className="full-width p-0 m-10 resend-btn"
                onClick={handleLink}
                disabled={verifyLoading}
              >
                Go to Login
              </Button>
            </Spin>
          </Card>
          <FooterContent />
        </div>
      </div>
    </>
  );
};
export default Confirmation;
