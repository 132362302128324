import { gql } from '../../../__generated__';

export const CREATE_FORM = gql(`
mutation ProjectFormCreation($data: CreateFormInput!) {
    projectFormCreation(data: $data) {
      data {
        id
        name
        projectId
      }
      message
    }
  }
`);

export const DELETE_FORM = gql(`
mutation DeleteForm($data: DeleteFormInput!) {
  deleteForm(data: $data) {
    message
  }
}`);

export const EDIT_FORMS = gql(`
  mutation EditForm($data: EditFormInput!) {
  editForm(data: $data) {
    message
  }
}`);
