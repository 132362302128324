import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Space, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { Google, LwForms, Microsoft } from '../../assets/svg/index';
import { MESSAGE, REGEX, ROUTES } from '../../common/constants';
import { formItemProps, formValidatorRules } from '../../common/utils';
import FooterContent from '../../components/FooterContent';
import {
  signInWithGooglePopup,
  signInWithMicrosoftPopup,
} from '../../firebase/collection/auth';
import useRouter from '../../hooks/useRouter';
import './auth.less';
import { FIREBASE_LOGIN, LOGIN } from './graphql/mutations';
import { useMutation } from '@apollo/client';
import { LoginUserInput, User } from '../../__generated__/graphql';
import { AppContext } from '../../AppContext';
import { useContext } from 'react';
import { AppContextType } from '../../types/appContext.type';
import { messageContext } from '../../components/AppContextHolder';

const { required, email } = formValidatorRules;

const Login = () => {
  const [form] = Form.useForm();
  const { navigate } = useRouter();
  const { initializeAuth } = useContext(AppContext) as AppContextType;
  const [loginMutate, { loading: loginLoading }] = useMutation(LOGIN, {
    fetchPolicy: 'network-only',
    onError() {}, // Always write this method for error handling in all mutation.
  });
  const [firebaseLoginMutate] = useMutation(FIREBASE_LOGIN, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  function successCallback(
    accessToken: string | null,
    userData: User,
    refreshToken?: string | null,
  ) {
    initializeAuth(accessToken, userData, refreshToken);
    navigate(ROUTES.MAIN);
  }
  const handleGoogleSignIn = async () => {
    try {
      const { user } = await signInWithGooglePopup();

      if (user) {
        const { token } = await user.getIdTokenResult();

        await firebaseLoginMutate({
          variables: { data: { token: token } },
          onError: () => {},
          onCompleted: (res) => {
            if (res?.firebaseLogin) {
              const userData = res?.firebaseLogin?.data;
              const accessToken = res?.firebaseLogin?.accessToken;
              const refreshToken = res?.firebaseLogin?.refreshToken;
              if (successCallback) {
                successCallback(accessToken!, userData!, refreshToken);
              }
            } else {
              messageContext.error('Something went wrong');
            }
          },
        });
      }
    } catch (error) {
      messageContext.error('Something went wrong');
    }
  };

  const handleMicrosoftSignIn = async () => {
    // Handle Microsoft sign in logic
    try {
      await signInWithMicrosoftPopup();

      // if (token) handleGoogleFinish(token);
    } catch (error) {
      // messageContext?.error(error?.message || 'Something went wrong!');
    }
  };

  const onFinish = (values: LoginUserInput) => {
    try {
      const formValues = {
        email: values?.email?.toLowerCase(),
        password: values?.password,
      };
      loginMutate({
        variables: { data: { ...formValues } },
        onCompleted: (response) => {
          if (response?.loginUser) {
            const accessToken = response?.loginUser?.accessToken ?? null;
            const userData = response?.loginUser?.data;
            const tokenRefresh = response?.loginUser?.refreshToken;
            if (successCallback) {
              successCallback(accessToken, userData!, tokenRefresh);
            }
          } else {
            form?.setFieldsValue(values);
          }
        },
        onError: (error) => {
          messageContext.error(error.message);
        },
      });
    } catch (error) {
      messageContext.error('Failed to Login!');
    }
  };

  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-start">
        <Card className="full-width">
          <Spin spinning={loginLoading} wrapperClassName="full-width">
            <div className="text-center m-16">
              <LwForms />
            </div>
            <Form
              name="Login"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              size="large"
              form={form}
            >
              <Form.Item
                name="email"
                hasFeedback
                rules={[{ ...required, message: MESSAGE?.required }, email]}
                normalize={formItemProps.normalize}
              >
                <Input prefix={<UserOutlined />} placeholder="Enter email" />
              </Form.Item>

              <Form.Item
                name="password"
                className="mb-8"
                rules={[
                  { ...required, message: MESSAGE?.required },
                  {
                    pattern: REGEX.PASSWORD,
                    message:
                      'Password must be at least 8 characters long that includes 1 number, and 1 special character',
                  },
                ]}
                hasFeedback
                normalize={formItemProps.normalize}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Enter password"
                />
              </Form.Item>
              <Form.Item className="text-right mb-8">
                <Link to={ROUTES.FORGET_PASSWORD}>Forgot password?</Link>
              </Form.Item>
              <Form.Item className=" full-width mb-8">
                <Button type="primary" className="full-width" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
              <div className="full-width continue-btn">
                <Button
                  type="default"
                  icon={<Google />}
                  onClick={handleGoogleSignIn}
                  className="full-width custom-button"
                >
                  Continue with Google
                </Button>

                <Button
                  type="default"
                  icon={<Microsoft />}
                  onClick={handleMicrosoftSignIn}
                  className="full-width custom-button"
                >
                  Continue with Microsoft
                </Button>
              </div>
              <Form.Item className="text-center mb-0">
                <Space>
                  Don't have an account yet ?
                  <Button
                    type="link"
                    className="p-0"
                    onClick={() => {
                      navigate(ROUTES.SIGNUP);
                    }}
                  >
                    Signup
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
        <FooterContent />
      </div>
    </div>
  );
};

export default Login;
